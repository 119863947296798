<script lang="ts">
  import Tooltip from "@ui/Tooltip.svelte";
  import Checkbox from "../show_author_new/newsletterSettings/Checkbox.svelte";
  import RelativePopup from "../show_author_new/RelativePopup.svelte";
  import { fly } from "svelte/transition";
  var HIGH_CONTRAST_CLASS = "ada-high-contrast";
  var MEDIUM_FONT_CLASS = "ada-medium-font-size";
  var LARGE_FONT_CLASS = "ada-large-font-size";
  var ACTIVE_CLASS = "active";

  export let isOpen: boolean = false;

  let smallBtn: HTMLButtonElement;
  let mediumBtn: HTMLButtonElement;
  let largeBtn: HTMLButtonElement;
  let enableHighContrast: boolean = false;

  function setToSmall() {
    largeBtn.classList.remove(ACTIVE_CLASS);
    mediumBtn.classList.remove(ACTIVE_CLASS);
    smallBtn.classList.add(ACTIVE_CLASS);
    document.body.classList.remove(MEDIUM_FONT_CLASS, LARGE_FONT_CLASS);
  }

  function setToMedium() {
    document.body.classList.remove(LARGE_FONT_CLASS);
    document.body.classList.add(MEDIUM_FONT_CLASS);
    largeBtn.classList.remove(ACTIVE_CLASS);
    mediumBtn.classList.add(ACTIVE_CLASS);
    smallBtn.classList.remove(ACTIVE_CLASS);
  }

  function setToLarge() {
    document.body.classList.remove(MEDIUM_FONT_CLASS);
    document.body.classList.add(LARGE_FONT_CLASS);
    largeBtn.classList.add(ACTIVE_CLASS);
    mediumBtn.classList.remove(ACTIVE_CLASS);
    smallBtn.classList.remove(ACTIVE_CLASS);
  }

  $: if (isOpen && smallBtn) {
    if (document.body.classList.contains(LARGE_FONT_CLASS)) {
      setToLarge();
    } else if (document.body.classList.contains(MEDIUM_FONT_CLASS)) {
      setToMedium();
    } else {
      setToSmall();
    }
  }

  $: if (enableHighContrast) {
    document.body.classList.add(HIGH_CONTRAST_CLASS);
  } else {
    document.body.classList.remove(HIGH_CONTRAST_CLASS);
  }
</script>

<div class="popup-position">
  <RelativePopup position="bottom" bind:isOpen>
    <button
      class="flex items-center h-full font-bold rounded outline-none text-15 text-nxgray-600 focus:outline-none clickable ada-indicator-button">
      <span aria-hidden="true" class="mr-0.5 pointer-events-none material-icons">accessibility_new</span>
      <span class="hidden pointer-events-none sm:block notranslate">Accessibility</span>
    </button>
    <span aria-hidden="true" slot="icon" class="material-icons text-18 text-nxgray-300"> accessibility_new </span>
    <span slot="title" class="font-medium text-14"> Accessibility </span>
    <div slot="content" class="flex flex-col items-start w-full text-13" transition:fly|local={{ duration: 150, y: -10 }}>
      <h2 class="font-normal text-nxgray-500 text-13">Font Size</h2>
      <div class="grid w-full grid-cols-3 gap-2 mt-2">
        <button
          aria-label="Small font size"
          bind:this={smallBtn}
          on:click={setToSmall}
          class="w-full rounded text-16 action bg-nxgray-100 text-nxgray-600">
          <span aria-hidden="true">Small</span>
        </button>
        <button
          aria-label="Medium font size"
          bind:this={mediumBtn}
          on:click={setToMedium}
          class="w-full rounded text-16 action bg-nxgray-100 text-nxgray-600">
          <span aria-hidden="true">Medium</span>
        </button>
        <button
          aria-label="Large font size"
          bind:this={largeBtn}
          on:click={setToLarge}
          class="w-full rounded text-16 action bg-nxgray-100 text-nxgray-600">
          <span aria-hidden="true">Large</span>
        </button>
      </div>
      <div class="mt-4">
        <Checkbox id="enable_high_contrast" text="Enable High Contrast Mode" bind:checked={enableHighContrast} />
      </div>
    </div>
  </RelativePopup>
</div>

<style>
  .popup-position {
    --right: -7.15rem;
    --top: 4rem;
    --chevron-left: 50%;
    --popup-width: 270px;
  }

  @media (min-width: 640px) {
    .popup-position {
      --right: -7.4rem;
      --chevron-left: 50%;
      --popup-width: 360px;
    }
  }

  .action {
    height: 50px;
  }

  :global(button.active) {
    @apply bg-nxgray-600;
    @apply text-white;
  }
  .text-16 {
    font-size: 16px !important;
  }
  .text-18 {
    font-size: 18px !important;
  }
  .text-24 {
    font-size: 24px !important;
  }

  :global(.ada-high-contrast) :global(.ada-indicator-button) {
    @apply text-green-600;
  }
</style>
