<script lang="ts">
  import Modal from "@ui/Modal.svelte";
  import { ServerError, getJson, postJson } from "@ui/http";
  import { validateEmail } from "./utils";
  import { getSocialButtonContext } from "../show_author_new/socialContext";
  import type { PopupData } from "./types";
  import { onMount } from "svelte";
  import { _token } from "@ui/currentUser";

  export let isOpen: boolean = false;
  export let listId: string;

  const errorCodeToMessage: { [key: string]: string } = {
    invalid_email: "The email you entered looks incorrect."
  };

  const context = getSocialButtonContext();
  let dataPromise: Promise<PopupData>;
  let fullName = "";
  let email = "";
  let canSend: boolean;
  let submitted = false;
  let submitting = false;
  let errorMessage: string | undefined;

  onMount(async () => {
    dataPromise = getJson<PopupData>("/app/flyer_actions/subscribe_to_author_modal_json/" + context.newsletterId);
  });

  async function doSubscribe() {
    if (canSend) {
      canSend = false;
      try {
        submitting = true;
        await postJson("/app/flyer_actions/subscribe_to_author_json/" + context.newsletterId, {
          list_id: listId,
          email,
          name: fullName,
          _token: $_token
        });
        submitted = true;
      } catch (e) {
        errorMessage = errorCodeToMessage[(e as ServerError).code] || ServerError.GENERIC_ERROR;
        canSend = true;
      } finally {
        submitting = false;
      }
    }
  }

  $: {
    canSend = fullName.trim().length > 0 && !!validateEmail(email);
    // errorMessage = undefined;
  }

  function close() {
    fullName = "";
    email = "";
    submitted = false;
    submitting = false;
  }
</script>

<div class="z-10">
  <Modal bind:isOpen --popup-width="400px" let:forceCloseModal on:close={close}>
    {#await dataPromise}
      Loading...
    {:then data}
      {#if submitted}
        <div class="flex justify-center w-full text-xl material-icons">mail</div>
        <div class="mt-4">
          Thank you for subscribing! Please confirm your email by clicking on the confirmation link you just received in your inbox.
        </div>
        <div class="flex justify-end w-full">
          <button
            class="px-3 py-1 mt-4 text-sm text-white bg-green-500 rounded shadow outline-none focus:outline-none clickable"
            on:click={forceCloseModal}>Close</button>
        </div>
      {:else}
        <div class="flex items-center justify-start w-full pb-4">
          <div class="w-10 h-10 bg-center bg-contain rounded-full" style="background-image: url({data.newsletter.authorPictureUrl})" />
          <div class="ml-2">
            <h2 class="p-0 m-0 font-bold text-md">Subscribe to {data.newsletter.authorName}</h2>
          </div>
        </div>
        <div class="flex items-center justify-center w-full p-2 leading-tight text-white bg-red-600 text-14" class:hidden={!errorMessage}>
          <span class="mr-2 material-icons text-18">error</span>
          {errorMessage}
        </div>
        <div class="flex flex-col justify-center w-full mt-4">
          <div class="flex flex-col items-center justify-between w-full">
            <label class="flex flex-col w-full text-sm font-medium">
              <span>Full name <span class="text-red-500">*</span></span>
              <input type="text" bind:value={fullName} autofocus />
            </label>
            <label class="flex flex-col w-full mt-4 text-sm font-medium">
              <span
                >Your email <span class="text-xs text-nxgray-300">(so they can reach you)</span><span class="text-red-500">*</span></span>
              <input type="email" bind:value={email} />
            </label>
          </div>
          <div class="flex justify-end w-full">
            <button
              class="px-3 py-1 mt-4 text-sm text-white rounded outline-none focus:outline-none"
              class:bg-green-500={canSend}
              class:clickable={canSend}
              class:shadow={canSend}
              class:bg-gray-400={!canSend}
              class:cursor-default={!canSend}
              on:click={doSubscribe}>
              {#if submitting}
                Subscribing...
              {:else}
                Subscribe
              {/if}</button>
          </div>
        </div>
      {/if}
    {/await}
  </Modal>
</div>

<style>
  input {
    border-radius: theme("borderRadius.DEFAULT");
    border-color: theme("borderColor.DEFAULT");
    border-width: theme("borderWidth.DEFAULT");
    padding-left: theme("padding.2");
    padding-right: theme("padding.2");
    padding-top: theme("padding.1");
    padding-bottom: theme("padding.1");
    width: 100%;
  }
</style>
