<script lang="ts">
  import { getShowContext } from "./showContext";
  import { isLoggedIn } from "@ui/currentUser";
  import { onMount } from "svelte";
  import { fade } from "svelte/transition";

  const context = getShowContext();
  const loggedIn = isLoggedIn;
  const newsletterElement = document.querySelector(".container-show")!;
  let pageFooter: HTMLDivElement;

  $: if (pageFooter) {
    calcPosition();
  }

  onMount(() => {
    calcPosition();
  });

  function calcPosition() {
    if (!newsletterElement || !pageFooter) {
      return;
    }

    const bottomOfNewsletter = newsletterElement.getBoundingClientRect().bottom + document.documentElement.scrollTop;

    if (bottomOfNewsletter + pageFooter.offsetHeight < window.innerHeight) {
      // The newsletter is shorter than the screen -> make the banner fixed to the bottom
      pageFooter.style.position = "fixed";
    } else {
      pageFooter.style.position = "unset";
      // Newsletter is longer than the screen -> just append the banner after the newsletter
      newsletterElement.parentNode!.appendChild(pageFooter);
    }

    // Show the footer
    pageFooter.style.display = "flex";
  }
</script>

<svelte:window on:resize={calcPosition} />

{#if context.showFullBar || context.showMinimalBar}
  <div
    bind:this={pageFooter}
    transition:fade={{ duration: 2000 }}
    class="bottom-0 z-0 justify-center hidden w-full shadow-inner brandon notranslate page-footer no-print"
    class:no-tablet={context.showMinimalBar}
    class:no-mobile={context.showMinimalBar}
    class:bg-transparent={context.showMinimalBar}
    class:bg-white={context.showFullBar}>
    {#if context.showFullBar}
      <div class="flex items-center justify-center w-full mx-auto">
        <div class="flex flex-wrap items-center justify-start px-4 py-8 md:flex-nowrap">
          <div class="">
            <h2 class="flex items-center leading-snug text-14 text-nxgray-600">
              Created with <img class="-mt-0.5 w-24 ml-2 mr-5" src={context.smoreImageUrl} alt="Smore" />
            </h2>
            <h3 class="mt-2 font-medium text-15">Communicate quickly and effectively with interactive newsletters.</h3>
            <p class="mt-1 font-normal leading-snug text-14 text-nxgray-500">
              Smore empowers educators to connect with their community, streamline school communications,<br /> and increase engagement.
            </p>
            <div class="flex items-center justify-start mt-4">
              {#if $loggedIn}
                <a
                  href="/app/pages/new_wizard?s=nl_footer"
                  class="inline-block px-10 py-2 font-bold text-white bg-orange-600 rounded shadow has-tooltip hover:bg-orange-500 clickable text-16 pop-above"
                  >Create a newsletter</a>
              {:else}
                <a
                  href="/"
                  class="inline-block px-4 py-2 font-bold text-white truncate bg-orange-600 rounded shadow md:px-10 has-tooltip hover:bg-orange-500 clickable text-16"
                  >Create a newsletter</a>
              {/if}
              <div class="hidden ml-4 md:block">
                <img src={context.itsAwesomeImageUrl} alt="Get started with free school newsletter templates!" />
              </div>
            </div>
          </div>
        </div>
      </div>
    {:else if context.showMinimalBar}
      <div class="px-4 py-1 mx-auto text-gray-500 bg-black rounded-t w-max text-13">
        <a href="/?ref=powered" target="_blank" title="Powered by Smore" class="flex items-center">
          <span>Powered by </span>
          <svg class="ml-2" viewBox="0 0 108 28" width="62" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M35.7439 10.5818c0-.80005.6448-1.52732 2.1493-1.52732 1.2895 0 2.794.4 4.2268.94545l.8955-2.98181c-1.5403-.76364-3.188-1.16364-5.1223-1.16364-3.5821 0-5.7314 2.03636-5.7314 5.01822 0 5.4909 7.6657 4.2181 7.6657 7.0181 0 1.0546-.6806 1.7091-2.1851 1.7091-1.4686 0-3.3313-.6545-4.8-1.3091l-.9672 3.0546c1.612.8727 3.5821 1.4182 5.6956 1.4182 3.5104 0 5.9462-2.0364 5.9462-4.9818 0-5.7819-7.7731-4.3273-7.7731-7.2Zm25.8627-4.76368c-1.8985 0-3.6537.87272-4.8 2.36363-.7881-1.56363-2.3642-2.36363-3.9761-2.36363-1.6836 0-3.3314.8-4.3702 2.4v-2h-3.4746V22.3999h3.6537v-9.5636c0-2.0364.8597-3.63637 2.9732-3.63637 1.8985 0 2.4716 1.41817 2.4716 3.78187v9.4181h3.6537v-9.5636c0-2 .8956-3.63637 2.9732-3.63637 1.9343 0 2.5074 1.45457 2.5074 3.92727v9.2727h3.6538v-8.9091c0-1.0909-.0358-2.0363-.1433-2.8363-.3582-3.38184-2.5433-4.83638-5.1224-4.83638Zm14.4 0c-4.1194 0-7.6657 3.34545-7.6657 8.65458 0 4.7272 2.8657 8.2181 7.3075 8.2181 4.191 0 7.6657-3.4545 7.6657-8.6545 0-5.2-3.2597-8.21818-7.3075-8.21818Zm-.1791 13.74548c-2.4 0-3.6537-2.6182-3.6537-5.2364 0-3.3091 1.4328-5.27272 3.6895-5.27272 2.4 0 3.6538 2.58182 3.6538 5.20002 0 3.3454-1.5403 5.3091-3.6896 5.3091ZM88.4006 8.76357V6.21812H84.926V22.3999h3.6537v-8.1091c0-2.9818 1.4329-4.76359 4.4777-4.87268V5.85448c-1.9702.07273-3.6538 1.30909-4.6568 2.90909ZM106.92 13.9272c0-4.76363-2.078-8.03636-6.34-8.03636-4.6212 0-7.451 4.32726-7.451 8.65456 0 5.7091 3.2955 8.1818 7.343 8.1818 2.221 0 4.191-.6909 6.018-1.5636l-.895-2.9091c-1.254.5454-2.651 1.1636-4.406 1.1636-2.5436 0-4.2272-1.1273-4.3704-4.1091h10.0294c.072-.4727.072-.8727.072-1.3818Zm-10.0298-1.2c.2149-1.7454 1.4686-3.63636 3.5458-3.63636 2.042 0 2.938 1.52726 2.938 3.63636h-6.4838Z"
              fill="white" />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M.674066 22.5221s.188997-.2283.903294-.1358c.41253.0533 1.0486.114 1.66457.1729 2.2522 1.2486 5.02203 1.7955 7.52357 1.8206 3.0421.0305 5.3751-.4428 7.1622-1.2225 1.0899-.0079 2.1262 0 3.0079.0721.8383.0692.9483.3516.9483.3516s.329.2827.2217.9513c-.083.5126-.1798 1.2384-.2634 1.7549-.0598.3995-.239.771-.5136 1.065 0 0-.0824.1793-.7702.2144-3.6216.1845-14.5269.2283-18.45756.4279-.82378.0419-1.09119-.1725-1.09119-.1725s-.243281-.1476-.283675-.7396c-.085541-1.2499-.233224-2.3167-.325162-3.6631-.048619-.7121.273253-.8972.273253-.8972Z"
              fill="#875140" />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M21.2011 13.9375c-.1057-.0236-.1861-.3615-.2024-.7232C20.6491 5.4989 13.7832 1.71186 10.4613.325256 9.39281-.120951 9.02451.0216969 9.02451.0216969c-.23268.054069-.26283.5536091-.22061.9411341.23761 2.187859.11112 4.414469-.90787 6.691089-.22793.50913-.50136.95774-.84919.92969-.33394.01993-.55546-.61506-.87496-1.0967-.85303-1.28584-1.77296-2.05462-2.36114-2.61229-.49862-.47278-.624-.25982-.624-.25982s-.26722.03524-.07421.6121c.0666.27166.09987.55056.09907.83041.03655.94206-.1667 2.20391-1.04313 3.6409C1.50846 10.7794-1.27252 14.328.689608 18.4653 2.47243 22.2236 7.03203 23.7008 10.8887 23.7416c11.5368.1221 11.9003-8.1515 11.6247-11.0613-.0523-.5514-.3456-.6459-.5242-.5889-.2851.0936-.2491.4662-.2559.6808-.0347 1.1928-.5322 1.1653-.5322 1.1653Z"
              fill="#F39C67" />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M9.35016 10.9639c.01827-.1896.0829-.3716.18809-.5296 0 0 .21294-.0447.59405.3759.1655.183.3138.3813.4429.5921.0913.1503.1462.3161.2778.3237.0881-.1495.1577-.3094.2072-.4761.2945-1.0086.4927-2.04332.5919-3.09004.0301-.29359.0183-.37073.1914-.57612.2851.01495.3205.09227.551.31371 1.7441 1.68297 3.7614 4.71365 3.9237 7.74255.0183.3422-.0042.6145.0675.6928 0 0 .0996.1024.3436-.2925.1949-.3156.3696-.6434.5229-.9816.1097-.2441.1298-.4224.2559-.4953.155.0122.193.2176.2459.5596.3447 2.2315-1.2566 6.8885-6.6199 6.8885-2.5538 0-5.2814-.9542-6.20334-3.62-.8671-2.5079 1.07218-7.3029 1.50464-7.2608.44671.0432-.1402 4.1205 1.53077 4.4641.90494.1676 1.27542-4.1478 1.38399-4.6309Z"
              fill="#FFDCA9" />
          </svg>
        </a>
      </div>
    {/if}
  </div>
{/if}
