<script lang="ts">
  import { onDestroy } from "svelte";
  import RelativePopup from "../show_author_new/RelativePopup.svelte";
  import GoogleLogo from "./GoogleLogo.svg";
  import { getShowContext } from "./showContext";
  import { fly } from "svelte/transition";
  import { reportTranslationEvent } from "@ui/reportEvents";

  export let isOpen: boolean = false;

  const context = getShowContext();

  let googleTranslateContainer: HTMLDivElement;
  let googleTranslateElement: HTMLDivElement;
  let languagesSelectElement: HTMLSelectElement | null = null;
  let wasEventlistenerAttachedToLanguageSelectElement: boolean = false;

  $: if (isOpen && googleTranslateContainer) {
    googleTranslateContainer.appendChild(googleTranslateElement);
    googleTranslateElement.classList.remove("invisible", "absolute", "opacity-0");
    if (!languagesSelectElement) {
      languagesSelectElement = googleTranslateContainer.querySelector("select");
      if (languagesSelectElement && !wasEventlistenerAttachedToLanguageSelectElement) {
        wasEventlistenerAttachedToLanguageSelectElement = true;
        languagesSelectElement.addEventListener("input", languageChanged);
      }
    }
  }

  onDestroy(() => {
    if (languagesSelectElement) {
      try {
        languagesSelectElement.removeEventListener("input", languageChanged);
        wasEventlistenerAttachedToLanguageSelectElement = false;
      } catch {
        // Ignore errors, we would like to continue working even if there was an error
      }
    }
  });

  // close the google translate
  function backToOriginal() {
    const restoreLanguageElement = document
      .getElementById(":1.container")
      // @ts-ignore
      ?.contentDocument?.getElementById(":1.restore") as HTMLButtonElement;
    restoreLanguageElement?.click();

    const closeGoogleTranslateElement = document
      .getElementById(":1.container")
      // @ts-ignore
      ?.contentDocument?.getElementById(":1.close") as HTMLAnchorElement;
    closeGoogleTranslateElement?.click();
    isOpen = false;
  }

  function languageChanged(e: Event) {
    const selectedLanguageCode = (e.target as HTMLSelectElement).value;
    reportTranslationEvent(context.newsletterId, selectedLanguageCode);
  }
</script>

{#if context.showTranslations}
  <!-- 
  This part need to be always rendered, since the google trnslate script will call this function when loaded
  And we need the element to exist in the DOM
 -->
  <div
    bind:this={googleTranslateElement}
    class="absolute invisible w-full opacity-0"
    id="google-translate-container"
    style={isOpen ? "--visibility:visible" : ""} />
  <span>
    <script type="text/javascript">
      //<![CDATA[
      function svltGoogleTranslateElementInit() {
        new google.translate.TranslateElement(
          {
            pageLanguage: "auto",
            autoDisplay: false
          },
          "google-translate-container"
        );
      }
      //]]>
    </script>
    <script src="//translate.google.com/translate_a/element.js?cb=svltGoogleTranslateElementInit"></script>
  </span>

  <div class="popup-position">
    <RelativePopup position="bottom" bind:isOpen>
      <button class="flex items-center h-full font-bold rounded outline-none text-15 text-nxgray-600 focus:outline-none clickable">
        <span aria-hidden="true" class="mr-0.5 pointer-events-none material-icons not-translated"> translate</span>
        <span class="pointer-events-none not-translated">{context.translateView ? "Translating..." : "Translate"}</span>
        <span aria-hidden="true" class="flex-shrink-0 hidden mr-2 text-green-600 pointer-events-none material-icons translated">
          translate</span>
        <span class="hidden text-green-600 pointer-events-none translated">Translated</span>
      </button>
      <span aria-hidden="true" slot="icon" class="material-icons text-18 text-nxgray-300"> translate </span>
      <span slot="title" class="font-medium text-14"> Translate </span>
      <div slot="content" transition:fly|local={{ duration: 150, y: -10 }}>
        <div bind:this={googleTranslateContainer} class="flex flex-col items-start w-full -mb-10">
          <div class="flex items-center justify-between w-full">
            <div class="font-normal text-13 text-nxgray-500">Choose a language</div>
            <div class="flex items-center transition-all transform -mt-14 text-12">
              Powered by <img class="mt-1 ml-1.5" src={GoogleLogo} alt="Google" />
            </div>
          </div>
        </div>
        <div class="items-center justify-center hidden mt-2 reset-content translate-flex">
          <button class="mt-2 font-medium text-teal-600 outline-none text-13 clickable" on:click={backToOriginal}>Back to Original</button>
        </div>
      </div>
    </RelativePopup>
  </div>
{/if}

<style>
  .popup-position {
    --right: -5rem;
    --top: 4rem;
    --chevron-left: 50%;
    --popup-width: 250px;
  }

  @media (min-width: 640px) {
    .popup-position {
      --right: -8.25rem;
      --chevron-left: 50%;
      --popup-width: 360px;
    }
  }

  :global(#google-translate-container .goog-te-gadget) {
    visibility: hidden;
  }
  :global(#google-translate-container .goog-logo-link img) {
    margin-left: 0.5rem;
  }

  :global(#google-translate-container .goog-te-combo) {
    visibility: var(--visibility, hidden);
    width: 100%;
    height: 30px;
    margin: 5px 0;
  }

  :global(#google-translate-container .goog-te-combo) {
    background: #fff;
    background-clip: border-box;
    border-radius: 3px;
    border: 1px solid #ccc;
    padding: 5px;
    font-size: 14px;
    -webkit-background-clip: padding-box;
    display: block;
    margin-bottom: 6px !important;
  }

  :global(#google-translate-container .goog-logo-link) {
    display: flex !important;
    flex-direction: row;
  }

  :global(.goog-te-banner-frame) {
    top: auto !important;
    left: auto !important;
    right: 0 !important;
    bottom: 0 !important;
    margin: 1rem !important;
    box-sizing: border-box !important;
    max-width: 94% !important;
    width: 600px !important;
    z-index: 99 !important;
    border-radius: 5px !important;
    background-color: white !important;
  }

  :global(.goog-te-banner-frame .goog-te-banner) {
    background-color: white !important;
    background-image: none !important;
  }

  :global(.translated-rtl .translated),
  :global(.translated-ltr .translated) {
    display: inline;
  }

  :global(.translated-rtl .not-translated),
  :global(.translated-ltr .not-translated) {
    display: none;
  }

  :global(.translated-ltr:not([lang^="en"])) :global(.translate-flex),
  :global(.translated-rtl:not([lang^="en"])) :global(.translate-flex) {
    display: flex;
  }
</style>
