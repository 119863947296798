<script lang="ts">
  import Modal from "@ui/Modal.svelte";
  import { ServerError, getJson, postJson } from "@ui/http";
  import { getSocialButtonContext } from "../show_author_new/socialContext";
  import { validateEmail } from "./utils";
  import type { PopupData } from "./types";
  export let isOpen: boolean = false;
  const context = getSocialButtonContext();
  let fullNameInput: HTMLInputElement;
  let popupData: PopupData;
  let fullName: string = "";
  let email: string = "";
  let message: string = "";
  let messageSent: boolean = false;
  let errorMessage: string | undefined;
  let canSend: boolean;
  let submitting: boolean = false;

  const errorCodeToMessage: { [key: string]: string } = {
    invalid_email: "The email you entered looks incorrect."
  };

  async function getModalData() {
    const result = await getJson<PopupData>("/app/flyer_actions/contact_author_modal_json/" + context.newsletterId);
    popupData = result;
  }

  async function sendMessage() {
    if (!submitting) {
      try {
        submitting = true;
        const result = await postJson<{ errorMessage?: string }>("/app/flyer_actions/contact_author_json", {
          id: context.newsletterId,
          email,
          name: fullName,
          token,
          message
        });
        messageSent = true;
      } catch (e) {
        errorMessage = errorCodeToMessage[(e as ServerError).code] || ServerError.GENERIC_ERROR;
      } finally {
        submitting = false;
      }
    }
  }

  function closePopup() {
    isOpen = false;
  }

  $: if (isOpen) {
    if (!newsletter) {
      messageSent = false;
      email = "";
      fullName = "";
      message = "";
      getModalData();
    }
  } else {
    messageSent = false;
    email = "";
    fullName = "";
    message = "";
    submitting = false;
  }

  $: newsletter = popupData && popupData.newsletter;
  $: token = popupData && popupData.token;
  $: canSend = fullName.trim().length > 0 && !!validateEmail(email) && message.trim().length > 0;
  $: firstName = newsletter && newsletter.authorName.split(" ")[0];
</script>

<div class="z-10">
  {#if popupData}
    <Modal bind:isOpen --popup-width="600px">
      {#if messageSent}
        <div class="py-10 mx-auto">
          <div class="flex flex-col items-center justify-center w-full">
            <h2 class="text-xl font-normal text-nxgray-500">Success!</h2>
            <span class="text-sm text-nxgray-500">Your message was sent to {newsletter.authorName}!</span>
          </div>
        </div>
        <div class="flex justify-end w-full mt-4">
          <button
            class="px-3 py-1 text-sm text-white bg-green-500 rounded shadow outline-none focus:outline-none hover:bg-green-600 clickable"
            on:click={closePopup}>Close</button>
        </div>
      {:else}
        <div class="flex items-start justify-start w-full pb-4 border-b">
          <div class="w-10 h-10 bg-center bg-contain rounded-full" style="background-image: url({newsletter.authorPictureUrl})" />
          <div class="ml-4">
            <h2 class="p-0 m-0 font-bold text-md">Send an email to {newsletter.authorName}</h2>
            <div
              class="flex items-center justify-center w-full p-2 leading-tight text-white bg-red-600 text-14"
              class:hidden={!errorMessage}>
              <span class="mr-2 material-icons text-18">error</span>
              {errorMessage}
            </div>
            <span class="text-xs text-nxgray-400"
              >If you want to send a message to {firstName}, fill in your contact info and message below.
            </span>
          </div>
        </div>

        <div class="flex flex-col items-center justify-between w-full mt-4 sm:flex-row">
          <label class="flex flex-col w-full text-sm font-medium">
            <span>Full name <span class="text-red-500">*</span></span>
            <input type="text" bind:this={fullNameInput} bind:value={fullName} autofocus />
          </label>
          <label class="flex flex-col w-full mt-4 text-sm font-medium sm:ml-4 sm:mt-0">
            <span>Your email <span class="text-xs text-nxgray-300">(so they can reach you)</span><span class="text-red-500">*</span></span>
            <input type="email" bind:value={email} />
          </label>
        </div>
        <label class="flex flex-col w-full mt-4 text-sm font-medium">
          <span>Message content <span class="text-red-500">*</span></span>
          <span class="text-xs font-normal text-nxgray-300"
            >Please be clear and polite. Include any information you want to send to {firstName}</span>
          <textarea class="px-2 py-1 border rounded" cols="30" rows="5" bind:value={message} />
        </label>
        <div class="flex justify-end w-full mt-4">
          <button
            class="px-3 py-1 text-sm text-white rounded outline-none focus:outline-none"
            class:bg-green-500={canSend}
            class:clickable={canSend}
            class:shadow={canSend}
            class:bg-gray-400={!canSend}
            on:click={sendMessage}>
            {#if submitting}
              Sending...
            {:else}
              Send message
            {/if}</button>
        </div>
      {/if}
    </Modal>
  {/if}
</div>

<style>
  input {
    border-radius: theme("borderRadius.DEFAULT");
    border-color: theme("borderColor.DEFAULT");
    border-width: theme("borderWidth.DEFAULT");
    padding-left: theme("padding.2");
    padding-right: theme("padding.2");
    padding-top: theme("padding.1");
    padding-bottom: theme("padding.1");
    width: 100%;
  }
</style>
