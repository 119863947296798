<script lang="ts" context="module">
  import NewsletterSmoreBranding from "./show_new/NewsletterSmoreBranding.svelte";
  import { createShowContext } from "./show_new/showContext";
  import Toolbar from "./show_new/Toolbar.svelte";
  import { createSocialButtonContext } from "./show_author_new/socialContext";
  import { onMount, tick } from "svelte";
  import ContactAuthorModal from "./show_new/ContactAuthorModal.svelte";
  import SubscribeToListModal from "./show_new/SubscribeToListModal.svelte";
  import TableOfContent from "./show_new/TableOfContent.svelte";
  import { handleVideoClick } from "./show_new/videoUtils";
  import { ServerError, postJson } from "@ui/http";
  import type { schema } from "@editor/schema";
  import PollBlockDynamicReplacer from "./show_author/PollBlockDynamicReplacer.svelte";

  const codeToMessage: Record<string, string> = {
    event_not_found: "Couldn't find this event. Try refreshing the page.",
    attendee_not_found: "Couldn't find this attendee.",
    invalid_email: "Your email doesn't appear to be valid.",
    empty_first_name: "First name can't be empty.",
    empty_last_name: "Last name can't be empty.",
    double_rsvp: "This email has already RSVPed. To change status, see your confirmation email."
  };
</script>

<script lang="ts">
  export let newsletterId: string;
  export let showPromotionBar: boolean;
  export let showFullBar: boolean = false;
  export let showMinimalBar: boolean = false;
  export let showSocialIcons: boolean;
  export let showAddReaction: boolean;
  export let showTranslations: boolean;
  export let showAccessibilityIcons: boolean;
  export let translateView: boolean;
  export let shortUrl: string;
  export let canonicalUrl: string;
  export let screenshotUrl: string;
  export let metaTitle: string;
  export let smoreBaseDomain: string;
  export let smoreImageUrl: string;
  export let itsAwesomeImageUrl: string;
  export let showTOC: boolean = false;
  export let showViewCount: boolean = false;
  export let viewCount: number = 0;
  export let hideToolbar: boolean = false;
  export let hasPolls: boolean | undefined = false;
  export let newsletter: schema.Newsletter;

  let hasContactAuthorButton: boolean = false;
  let hasSubscribeToListButton: boolean = false;

  let isContactAuthorOpen: boolean = false;
  let isSubscribeToListOpen: boolean = false;
  let listId: string = "";

  createShowContext({
    showPromotionBar,
    showFullBar,
    showMinimalBar,
    showSocialIcons,
    showAddReaction,
    showTranslations,
    showAccessibilityIcons,
    translateView,
    smoreImageUrl,
    itsAwesomeImageUrl,
    showTOC,
    showViewCount,
    viewCount,
    hideToolbar,
    hasPolls,
    newsletterId
  });

  createSocialButtonContext({
    newsletterId,
    shortUrl,
    metaTitle,
    canonicalUrl,
    screenshotUrl,
    smoreBaseDomain
  });

  onMount(async () => {
    // Extract anchor from URL
    const urlParts = document.URL.split("#");
    let urlAnchor;
    if (urlParts.length > 1) {
      urlAnchor = urlParts[1];
    }

    // Handle "Contact Author" click in the email
    const contactUserButton = document.querySelector("#contact_user");
    if (contactUserButton) {
      hasContactAuthorButton = true;
      contactUserButton.addEventListener("click", showContactAuthorModal);
      if (urlAnchor === "contact_user") {
        window.requestAnimationFrame(async () => {
          await tick();
          (contactUserButton as any).click();
        });
      }
    }

    // Handle "Subscribe to Author" click in the email
    const subscribeUserButton = document.querySelector("#subscribe_user");
    if (subscribeUserButton) {
      hasSubscribeToListButton = true;
      listId = (subscribeUserButton as HTMLElement).dataset.listId || "";
      subscribeUserButton.addEventListener("click", showSubscribeToListModal);
      if (urlAnchor === "subscribe_user") {
        window.requestAnimationFrame(async () => {
          await tick();
          (subscribeUserButton as any).click();
        });
      }
    }

    // Handle Video click in the email
    const videoBlockAnchor = document.querySelector(`#${urlAnchor}`);
    if (videoBlockAnchor) {
      const videoButton = document.querySelector(`[data-block-id=${urlAnchor}] button`);
      if (videoButton) {
        handleVideoClick(videoButton);
      }
    }

    registerRSVPEvents();

    return () => {
      // @Eli, I don't think we need this as the main component never dismounts
      unregisterRSVPEvents();
    };
  });

  // Handle tracked url fixes
  async function handleClicks(e: MouseEvent | TouchEvent) {
    const targetElement = e.target;
    if (!targetElement) {
      return;
    }

    // Handle links
    const linkWrapper = targetElement.closest("a.skip-tracking[track-href]");
    if (linkWrapper) {
      // @ts-ignore
      if (linkWrapper.classList.contains("fancy-pic") && e.buttons === 1) {
        return;
      }
      linkWrapper.href = linkWrapper.getAttribute("track-href") || linkWrapper.href;
    }

    handleVideoClick(targetElement);
  }

  function showContactAuthorModal() {
    isContactAuthorOpen = true;
  }

  function showSubscribeToListModal(e: Event) {
    const evt = e as MouseEvent;
    if (listId.trim().length === 0) {
      listId = (e.target as HTMLButtonElement).dataset.listId || "";
    }
    isSubscribeToListOpen = true;
  }

  async function submitRSVPForm(e: Event) {
    e.stopImmediatePropagation();
    e.preventDefault();
    const form = e.target as HTMLFormElement;
    const button = (e as SubmitEvent).submitter as HTMLButtonElement;
    const firstNameInput = form.querySelector(".first-name") as HTMLInputElement;
    const lastNameInput = form.querySelector(".last-name") as HTMLInputElement;
    const emailInput = form.querySelector(".email-address") as HTMLInputElement;

    const pageId = form.dataset.pageId;
    const eventId = form.dataset.eventId;

    const firstName = firstNameInput.value;
    const lastName = lastNameInput.value;
    const email = emailInput.value;

    const rsvpMessage = form.querySelector(".rsvp-message");
    if (rsvpMessage) {
      form.removeChild(rsvpMessage);
    }

    const buttonText = button.value;
    try {
      button.classList.remove("bg-green-600");
      button.classList.add("bg-nxgray-300", "pointer-events-none");
      button.value = "Please wait...";
      await postJson("/app/flyer_actions/rsvp_attend_json", {
        page: pageId,
        event: eventId,
        firstName,
        lastName,
        email
      });
      const div = document.createElement("div");
      div.classList.add("bg-green-600", "text-white", "w-full", "py-2", "mt-2", "text-sm", "text-center", "rsvp-message");
      div.innerText = `${email} is now attending this event.`;
      form.appendChild(div);
    } catch (e) {
      let errorMsg = codeToMessage[e.code] || ServerError.GENERIC_ERROR;
      const div = document.createElement("div");
      div.classList.add("bg-red-500", "text-white", "w-full", "py-2", "mt-2", "text-sm", "text-center", "rsvp-message");
      div.innerText = errorMsg;
      form.appendChild(div);
    } finally {
      button.value = buttonText;
      button.classList.add("bg-green-600");
      button.classList.remove("bg-nxgray-300", "pointer-events-none");
    }
  }

  function registerRSVPEvents() {
    const rsvpForms = document.querySelectorAll(".new-event-rsvp-form");
    rsvpForms.forEach((form) => {
      form.addEventListener("submit", submitRSVPForm);
    });
  }

  function unregisterRSVPEvents() {
    const rsvpForms = document.querySelectorAll(".new-event-rsvp-form");
    rsvpForms.forEach((form) => {
      form.removeEventListener("submit", submitRSVPForm);
    });
  }
</script>

<PollBlockDynamicReplacer {newsletter} {hasPolls} allowTableOfContent={showTOC} />

<svelte:body on:mousedown={handleClicks} on:touchstart={handleClicks} />

{#if showTOC}
  <TableOfContent />
{/if}

{#if hasContactAuthorButton}
  <ContactAuthorModal bind:isOpen={isContactAuthorOpen} />
{/if}

{#if hasSubscribeToListButton}
  <SubscribeToListModal bind:isOpen={isSubscribeToListOpen} bind:listId />
{/if}

<Toolbar />
<NewsletterSmoreBranding />

<style>
  :global(a:focus, button:focus) {
    outline: auto !important;
    outline-color: #505060 !important;
  }
</style>
