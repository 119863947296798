<script lang="ts">
  import ShareWithSocialButton from "../show_author_new/ShareWithSocialButton.svelte";
  import RelativePopup from "../show_author_new/RelativePopup.svelte";
  import CopyToClipboard from "@ui/CopyToClipboard.svelte";
  import { getSocialButtonContext } from "../show_author_new/socialContext";
  import { reportUserEvent, USER_EVENTS } from "@ui/reportEvents";
  import { fly } from "svelte/transition";

  export let isOpen: boolean = false;
  const context = getSocialButtonContext();

  $: if (isOpen) {
    reportUserEvent(USER_EVENTS.ShareButtonPopupOpen, {
      newsletterId: context.newsletterId
    });
  }

  function reportUserCopiedLink() {
    reportUserEvent(USER_EVENTS.ShareButtonPopupCopyLink, {
      newsletterId: context.newsletterId
    });
  }

  async function reportUserCopiedEmbedCode() {
    reportUserEvent(USER_EVENTS.EmbedNewsletterCopyEmbedCode, {
      newsletterId: context.newsletterId
    });
  }

  function getTitle() {
    const title = document.querySelector('meta[property="og:title"]')?.attributes.getNamedItem("content")?.value;
    const isSafe = !title || !title.match(/[\u0000-\u0008\u000B-\u000C\u000E-\u001F\uD800-\uDFFF\uFFFE-\uFFFF]/);
    if (!isSafe || !title) {
      return "Smore newsletter";
    }

    return title.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/"/g, "&quot;");
  }

  function getEmbedIframeCode() {
    return `<iframe width="100%" height="600" src="${
      context.shortUrl
    }?embed=1" title="${getTitle()}" scrolling="auto" frameborder="0" allowtransparency="true" style="min-width: 320px;border: none;"></iframe>`;
  }
</script>

<div class="popup-position">
  <RelativePopup position="bottom" bind:isOpen>
    <button class="flex items-center h-full font-bold rounded outline-none text-15 text-nxgray-600 focus:outline-none clickable">
      <span aria-hidden="true" class="mr-0.5 pointer-events-none material-icons"> share </span>
      <span class="hidden pointer-events-none sm:block notranslate">Share</span>
    </button>
    <span aria-hidden="true" slot="icon" class="material-icons text-18 text-nxgray-300"> share </span>
    <span slot="title" class="font-medium text-14"> Share newsletter </span>

    <div slot="content" class="flex flex-col items-start w-full" transition:fly|local={{ duration: 150, y: -10 }}>
      <h2 class="font-normal text-nxgray-500 text-13">Share on social media</h2>
      <div class="flex items-center justify-between w-full mt-2">
        <div class="overflow-hidden rounded">
          <ShareWithSocialButton socialMedia="facebook" colorOnHover={false} --height="30px" />
        </div>
        <div class="overflow-hidden rounded">
          <ShareWithSocialButton socialMedia="twitter" colorOnHover={false} --height="30px" />
        </div>
        <div class="overflow-hidden rounded">
          <ShareWithSocialButton socialMedia="pinterest" colorOnHover={false} --height="30px" />
        </div>
      </div>
      <h2 class="mt-3 font-normal text-nxgray-500 text-13">Share with link</h2>
      <div class="flex items-center justify-between w-full p-2 pr-2 mt-1 font-normal bg-gray-100 border rounded-md text-13">
        <div class="flex items-center">
          <span aria-hidden="true" class="mr-2 material-icons text-18 text-nxgray-300"> link </span>
          <span>{context.shortUrl}</span>
        </div>
        <CopyToClipboard textToCopy={context.shortUrl} on:copied={reportUserCopiedLink}
          ><span class="flex items-center font-medium text-teal-700 hover:text-teal-600 clickable text-13">
            <span aria-hidden="true" class="mr-1 material-icons text-15"> content_copy </span>
            Copy
          </span></CopyToClipboard>
      </div>

      <label class="flex items-center justify-between w-full pr-2 mt-4 font-normal text-nxgray-500 text-13" for="iframe-embed"
        >Embed code for website/blog: <div class="text-teal-700">
          <CopyToClipboard textToCopy={getEmbedIframeCode()} on:copied={reportUserCopiedEmbedCode}
            ><span class="flex items-center font-medium hover:text-teal-600 clickable">
              <span aria-hidden="true" class="mr-1 material-icons text-15"> content_copy </span>
              Copy
            </span></CopyToClipboard>
        </div>
      </label>

      <textarea
        rows="5"
        class="w-full px-2 py-1 mt-1 font-normal bg-gray-100 border rounded-md resize-none text-nxgray-400 text-14 sm:text-13 focus:outline-none"
        name="iframe-embed"
        readonly
        on:focus={(e) => {
          // @ts-ignore
          e.target.select();
        }}
        value={getEmbedIframeCode()} />

      <p class="px-4 mt-3 leading-snug text-center text-12 text-nxgray-500">
        Embed code is for website/blog and not email.<br />
        <a
          class="font-medium link clickable hover:underline"
          href="https://help.smore.com/article/1135-mass-notification-system-feature-faq?s=embed_faq"
          target="_blank">Click here</a>
        to see how to embed on your Mass Notification System.
      </p>
    </div>
  </RelativePopup>
</div>

<style>
  .popup-position {
    --right: -17.35rem;
    --top: 4rem;
    --popup-width: 360px;
    --chevron-left: 17%;
  }

  @media (min-width: 640px) {
    .popup-position {
      --right: -9rem;
      --chevron-left: 50%;
    }
  }
</style>
